<template>
  <footer class="flex flex-col justify-center bg-o_purple-100 text-t_white text-lg py-7 pb-9 rounded-2xl">
    <p class="mt-2 text-center" v-for="info in footers" :key="info">{{ info }}</p>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data: function () {
    return {
      footers: [
        '© 2021 正瀚生技股份有限公司 CH Biotech R&D Co., LTD.',
        '540001 南投縣南投市文獻路89號',
        '版本: 1.0.9 更新於 2023/10/-- 18:04',
        '國立台灣大學生物環境系統工程學系 維運'
      ]
    }
  }
}
</script>

<style scoped>

</style>
