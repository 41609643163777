class Config {
  constructor () {
    this.graphBackend = 'https://www.agri-trace-sys-graph.chbio.info/'
    this.basicBackendBaseURL = 'https://www.agri-trace-sys-blockchain.chbio.info/'
    this.recordGetMaxAmountBasedOnBackendSet = 10
    // 動態制或栽培曆相關設定
    this.minSelectedDate = '2021-01-01'
    this.calendarBackendURL = 'https://www.agri-trace-sys-calendar.chbio.info/'
  }
}

export default new Config()
